<template>
  <div class="analytics">
    <h3 class="analytics__title">📊 Аналитика</h3>

    <div class="analytics__tabs">
      <div
          v-for="(type, index) in salesTypes"
          :key="index"
          class="analytics__tab"
          :class="{ 'active': sales_type === type.id }"
          @click="changeSalesType(type.id)"
      >
        <p>{{ type.label }}</p>
      </div>
    </div>

    <div v-if="sales_type === 1 && weekly_result.total_orders" class="analytics__section">
      <div class="analytics__summary">
        <p><b>📦 Всего заказов за неделю:</b> {{ weekly_result.total_orders }} шт</p>
        <p><b>💰 Сумма продаж за неделю:</b> {{ formatCurrency(weekly_result.total_revenue) }}</p>
        <p><b>📈 Прибыль за неделю:</b> {{ formatCurrency(weekly_result.net_profit) }} </p>
      </div>

      <div class="analytics__grid">
        <div v-for="(week, index) in statistics" :key="index" class="analytics__card mb-4">
          <p class="analytics__day"><b>{{ week.weekday }}</b></p>
          <div  class="mb-4"  v-for="(payment, i) in week.payment_types" :key="i">
            <p><b>{{ payment.payment_type }}</b></p>
            <p>Продано: {{ payment.total_orders }} шт товар</p>
            <p>Выручка: {{ formatCurrency(payment.total_revenue) }} </p>
            <p>Прибыль: {{ formatCurrency(payment.net_profit) }} </p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="sales_type === 2" class="analytics__section">
      <div class="analytics__summary">
        <p><b>📦 Всего заказов за год:</b> {{ yearlyTotals.total_orders }} шт </p>
        <p><b>💰 Сумма продаж за год:</b> {{ formatCurrency(yearlyTotals.total_revenue) }} </p>
        <p><b>📈 Прибыль за год:</b> {{ formatCurrency(yearlyTotals.net_profit) }} </p>
      </div>

      <div class="analytics__grid">
        <div v-for="(month, index) in groupedOrders" :key="index" class="analytics__card mb-4">
          <p class="analytics__month"><b>{{ month.month }}</b></p>
          <div class="mb-4" v-for="(payment, i) in month.payment_types" :key="i">
            <p><b>{{ payment.payment_type }}</b></p>
            <p>Продано: {{ payment.total_orders }} шт товар</p>
            <p>Выручка: {{ payment.total_revenue }} </p>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Analytics",
  data() {
    return {
      sales_type: 1,
      statistics: [],
      weekly_result: {},
      groupedOrders: [],
      yearlyTotals: {},
      salesTypes: [
        { id: 1, label: "Еженедельно" },
        { id: 2, label: "Ежемесячно" }
      ]
    };
  },
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat('ru-RU', {
        style: 'decimal',
        minimumFractionDigits: 0
      }).format(value) + ' тнг';
    },
    changeSalesType(type) {
      this.sales_type = type;
      type === 1 ? this.getStatisticsWeek() : this.getStatisticsMonth();
    },
    totalRevenueForWeek(paymentTypes) {
      return this.formatCurrency(paymentTypes.reduce((sum, p) => sum + parseInt(p.total_revenue), 0));
    },
    getTotalRevenueForMonth(paymentTypes) {
      return this.formatCurrency(paymentTypes.reduce((sum, p) => sum + parseInt(p.total_revenue), 0));
    },
    async getStatisticsWeek() {
      try {
        const res = await this.$axios.get(`${this.$API_URL}sales/week`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
        });
        this.statistics = res.data.data;
        this.weekly_result = res.data.weekly_totals;
      } catch (error) {
        console.error(error);
      }
    },
    async getStatisticsMonth() {
      try {
        const res = await this.$axios.get(`${this.$API_URL}sales/month`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
        });
        this.groupedOrders = res.data.data;
        this.yearlyTotals = res.data.monthly_totals;
      } catch (error) {
        console.error(error);
      }
    }
  },
  mounted() {
    this.getStatisticsWeek();
  }
};
</script>

<style lang="scss" scoped>
.analytics {
  padding: 20px;
  font-family: "Arial", sans-serif;

  &__title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;

    .analytics__tab {
      cursor: pointer;
      padding: 10px 20px;
      border-radius: 8px;
      background: #f5f5f5;
      transition: background 0.3s ease, color 0.3s ease;

      &.active {
        background: #F5A623;
        color: white;
        font-weight: bold;
      }
    }
  }

  &__section {
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  &__summary {
    margin-bottom: 15px;
    p {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 15px;
  }

  &__card {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }

  &__day, &__month {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__total {
    font-size: 16px;
    font-weight: bold;
    color: #F5A623;
    margin-top: 10px;
  }
}
</style>
